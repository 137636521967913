import type { Auth } from './api/types'
import { ref } from 'vue'
import { sanitizeObject } from '../utils/object'
import { type BaseStorage, MemoryStorage } from '../utils/storage'
import { EventManager } from '../utils/storage/events'
import { Obfuscator } from '../utils/storage/utils'
import { toRef } from '../utils/vue/decorators'

type Tokens = Auth.Tokens | Auth.TokensRaw

interface TokensProviderEvents {
  init: void
  authorized: void
  logout: void
}

class TokensProvider extends EventManager<TokensProviderEvents> {
  #STORAGE_KEY = Obfuscator.encrypt('auth-tokens')
  protected storage: BaseStorage = new MemoryStorage()

  /** Whether the http storage is ready */
  @toRef(true)
  declare isReady: boolean

  get tokens(): Auth.Tokens {
    return this.storage.get(this.#STORAGE_KEY) ?? {}
  }

  set tokens(tokens: Tokens) {
    const i: any = Object.assign({}, tokens)

    // Update
    this.storage.set(this.#STORAGE_KEY, {
      accessToken: i.access_token || i.accessToken,
      refreshToken: i.refresh_token || i.refreshToken
    })

    if (this.isAuthorized)
      this.emit('authorized')
  }

  get isAuthorized() {
    const i = this.tokens
    return !!i.accessToken && !!i.refreshToken
  }

  protected validate(tokens: Tokens) {
    const i: any = Object.assign({}, tokens)
    const result = {
      accessToken: i.access_token || i.accessToken,
      refreshToken: i.refresh_token || i.refreshToken
    }

    return !(!result.accessToken || !result.refreshToken)
  }

  login(tokens: Tokens) {
    this.tokens = tokens
    return this.isAuthorized
  }

  logout(emit = true) {
    this.storage.remove(this.#STORAGE_KEY)
    if (emit)
      this.emit('logout')
  }

  setStorage(source: BaseStorage) {
    this.isReady = source.isReady

    source.once('init', () => {
      this.emit('init')
      this.isReady = true
    })

    // Trigger reactive `isAuthorized` state
    source.on('update', (keys) => {
      if (keys.includes(this.#STORAGE_KEY) && this.isAuthorized)
        this.emit('authorized')
    })

    this.storage = source
  }

  /** Resolved when the storage tokens are authorized/validated. */
  async onAuthorized() {
    if (!this.isReady)
      await this.resolve('init')

    if (this.isAuthorized)
      return Promise.resolve()

    return this.resolve('authorized')
  }

  protected resolve(key: keyof TokensProviderEvents) {
    return new Promise<void>(resolve => this.once(key, resolve))
  }
}

export class HTTPStorage extends TokensProvider {
  #isAuthorizedRef = ref(false)

  override get isAuthorized() {
    this.#isAuthorizedRef.value = super.isAuthorized
    return this.#isAuthorizedRef.value
  }

  // ? URL Based Auth
  /** Gets auth data from url hash and sets the token */
  authorizeFromURL() {
    interface AuthData {
      token: string
      redirect?: string
    }

    if (!location.hash)
      return

    try {
      const hash = window.location.hash.substring(1)

      const { token, redirect }: AuthData = JSON.parse(window.atob(hash))
      this.tokens = { accessToken: token, refreshToken: token }

      if (redirect)
        location.href = redirect
    }
    catch (error) {
      console.error('Failed to login', error)
    }
  }

  /**
   * Returns hash for url based authorization in the webapp
   * @param redirect - Passing null stops redirect
   */
  getAuthURL(base = 'auth', redirect: string | null = '/', config: {
    /** Query params */
    params?: Record<string, any>
  } = {}) {
    const url = new URL(base, import.meta.env.VITE_APP_WEBAPP_ORIGIN)

    // Append query params
    if (config.params) {
      Object.entries(sanitizeObject(config.params))
        .forEach(entry => url.searchParams.set(...entry))
    }

    // Assign auth hash
    url.hash = window.btoa(
      JSON.stringify({ token: this.tokens.accessToken, redirect })
    )

    return url.href
  }
}
