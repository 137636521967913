import payload_plugin_q3qJSeQfJx from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.8.0_magicast@0.3.5_pinia@2.2.8_typescript@5.7.2_vue@3.5.13_typescript@5.7.2___r_ugxwwwhafhmfpz62kz5qbmjl6m/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_1eIkWxfcAS from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@azure+identity@4.5.0_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.16.0__nsdly4xsp7keh5xv2tfd7yfd3e/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_4BFeelx0BK from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@azure+identity@4.5.0_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.16.0__nsdly4xsp7keh5xv2tfd7yfd3e/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_FQ3BuF4h6H from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@azure+identity@4.5.0_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.16.0__nsdly4xsp7keh5xv2tfd7yfd3e/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_V5yh8NQww1 from "/vercel/path0/node_modules/.pnpm/nuxt-site-config@2.2.18_magicast@0.3.5_rollup@4.28.0_vite@6.0.2_@types+node@22.10.1_jiti@2.4._qptcxicbmzzhvwonlpoidmuyca/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_aw3xnPJvb8 from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@azure+identity@4.5.0_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.16.0__nsdly4xsp7keh5xv2tfd7yfd3e/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_A7SRDOh9gs from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@azure+identity@4.5.0_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.16.0__nsdly4xsp7keh5xv2tfd7yfd3e/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_Z4X1iQP3Zi from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@azure+identity@4.5.0_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.16.0__nsdly4xsp7keh5xv2tfd7yfd3e/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import view_transitions_client_uVFDUMba0m from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@azure+identity@4.5.0_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.16.0__nsdly4xsp7keh5xv2tfd7yfd3e/node_modules/nuxt/dist/app/plugins/view-transitions.client.js";
import chunk_reload_client_BBn679yAtg from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@azure+identity@4.5.0_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.16.0__nsdly4xsp7keh5xv2tfd7yfd3e/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_Bsn0k5BL9V from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.8.0_magicast@0.3.5_pinia@2.2.8_typescript@5.7.2_vue@3.5.13_typescript@5.7.2___r_ugxwwwhafhmfpz62kz5qbmjl6m/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/apps/webapp/.nuxt/components.plugin.mjs";
import prefetch_client_ZE7UjrCEf3 from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@azure+identity@4.5.0_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.16.0__nsdly4xsp7keh5xv2tfd7yfd3e/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import unocss_MzCDxu9LMj from "/vercel/path0/apps/webapp/.nuxt/unocss.mjs";
import plugin_lZWUieGnJ9 from "/vercel/path0/node_modules/.pnpm/pinia-plugin-persistedstate@4.1.3_@pinia+nuxt@0.8.0_magicast@0.3.5_pinia@2.2.8_typescript@5.7_ljtjdlq7lkpjcsguf5de77ibd4/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import titles_wzV0KbWefZ from "/vercel/path0/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.23_h3@1.13.0_magicast@0.3.5_rollup@4.28.0_vite@6.0.2_@types+node@22.10.1_oplmyqfjktbkpmfcursx7vxnyy/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import siteConfig_3hx1EcS8Mz from "/vercel/path0/node_modules/.pnpm/nuxt-seo-experiments@4.0.1_magicast@0.3.5_rollup@4.28.0_vite@6.0.2_@types+node@22.10.1_jiti@2_rq56feddjtl54ongowrbsnnbju/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_zno7qXC34l from "/vercel/path0/node_modules/.pnpm/nuxt-seo-experiments@4.0.1_magicast@0.3.5_rollup@4.28.0_vite@6.0.2_@types+node@22.10.1_jiti@2_rq56feddjtl54ongowrbsnnbju/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import _00_setup_OesUu83x3t from "/vercel/path0/packages/ui/plugins/00.setup.ts";
import defaults_ZlPHh08rfO from "/vercel/path0/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.23_h3@1.13.0_magicast@0.3.5_rollup@4.28.0_vite@6.0.2_@types+node@22.10.1_oplmyqfjktbkpmfcursx7vxnyy/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.js";
export default [
  payload_plugin_q3qJSeQfJx,
  revive_payload_client_1eIkWxfcAS,
  unhead_4BFeelx0BK,
  router_FQ3BuF4h6H,
  _0_siteConfig_V5yh8NQww1,
  payload_client_aw3xnPJvb8,
  navigation_repaint_client_A7SRDOh9gs,
  check_outdated_build_client_Z4X1iQP3Zi,
  view_transitions_client_uVFDUMba0m,
  chunk_reload_client_BBn679yAtg,
  plugin_vue3_Bsn0k5BL9V,
  components_plugin_KR1HBZs4kY,
  prefetch_client_ZE7UjrCEf3,
  unocss_MzCDxu9LMj,
  plugin_lZWUieGnJ9,
  titles_wzV0KbWefZ,
  siteConfig_3hx1EcS8Mz,
  inferSeoMetaPlugin_zno7qXC34l,
  _00_setup_OesUu83x3t,
  defaults_ZlPHh08rfO
]