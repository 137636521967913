import { getStorage, initApp, Platform, type StorageType } from 'core'

declare module '#app' {
  interface PageMeta {
    storage?: StorageType
    platform?: Platform

    /** When false, pinia stores will not be synced */
    sync?: boolean
  }
}

export default defineNuxtPlugin({
  name: 'app-setup',
  parallel: true,
  async setup(nuxtApp) {
    if (!import.meta.client)
      return

    const app = nuxtApp.vueApp
    const pinia = app.$nuxt.$pinia
    const { meta } = useRoute()

    // Clear nuxt error handler
    app.config.errorHandler = undefined

    initApp(app, {
      pinia,
      platform: meta.platform || Platform.WEBAPP,
      storage: getStorage(meta.storage),
      sync: meta.sync,
      mount: false
    })

    console.log('🚀 Webapp initialized')
  }
})
