import type { FetchLike, Wretch, WretchError } from 'wretch'

import type { Auth } from './api/types'
import wretch from 'wretch'
import { ErrorService } from '../diagnostics'
import { usePromiseRunner } from '../utils/object'
import { HTTPStorage } from './storage'

const log = console.debug.bind(null, '🌍 [HTTP]:')

export abstract class HTTPController extends HTTPStorage {
  /** Middleware for HTTP */
  protected middleware() {
    // ! Use `this.storage` to get the latest storage
    return (next: FetchLike): FetchLike => async (url, opts) => {
      // ? Wait for active refresh sessions
      await this.#refreshTokens.current()

      // ? Wait for authorization
      if (!this.isAuthorized) {
        log('Waiting for authorization', url)
        await this.onAuthorized()
        log('Authorized. Resuming request', url)
      }

      // ? Remove duplicate slashes
      const cleanedURL = url.replace(/([^:]\/)\/+/g, '$1')

      // ? Resume request with access_token
      const request = next(cleanedURL, {
        ...opts,
        headers: {
          ...(opts.headers || {}),
          Authorization: `Bearer ${this.tokens.accessToken}`
        }
      })

      return request
    }
  }

  /** Force logs out user */
  #logout(error: any) {
    log('Failed to refresh tokens. Logging out.', { error })

    this.logout()

    if (import.meta.env.DEV)
      return alert('Logged out. refresh on own accord')

    location.reload()
  }

  #refreshTokens = usePromiseRunner(async (onSuccess: (tokens: Auth.Tokens) => void) => {
    log('Requesting new auth tokens')

    try {
      const refreshToken = this.tokens.refreshToken
      const { access_token: accessToken } = await wretch(`${import.meta.env.VITE_APP_API_URI}/refresh`)
        .auth(`Bearer ${refreshToken}`)
        .post()
        .json<Auth.TokensRaw>()

      if (!accessToken)
        throw new Error('/refresh returned null access_token')

      const tokens = { accessToken, refreshToken }

      log('New tokens acquired')

      // Authorize with new tokens
      this.login(tokens)
      onSuccess(tokens)

      return tokens
    }
    catch (error) {
      this.#logout(error)
    }
  })

  protected onError(error: WretchError, request: Wretch<unknown, unknown, undefined>) {
    log('Error caught. Trying to handle...', { error, request })

    const AUTH_ISSUES = [
      'Token has expired',
      'Not enough segments',
      'Signature verification failed'
    ]

    // Refresh authorization
    if (error.status === 401 || AUTH_ISSUES.some(e => e.includes(error.json?.msg))) {
      return this.#refreshTokens.run(
        tokens => request.auth(`Bearer ${tokens.accessToken}`).fetch().json()
      )
    }

    // Otherwise throw error
    ErrorService.log(error, {
      level: 'fatal',
      contexts: {
        'Network Data': {
          url: request._url,
          payload: request._options
        }
      }
    })

    log('Network error occurred. Reported to sentry', { error })
    return error.json
  }
}
