import { default as _91_46_46_46slug_93fQAS6l75CuMeta } from "/vercel/path0/apps/webapp/pages/[...slug].vue?macro=true";
import { default as indexdFfuSyifYuMeta } from "/vercel/path0/apps/webapp/pages/addons/zoho/index.vue?macro=true";
import { default as recordfmzjwK7XovMeta } from "/vercel/path0/apps/webapp/pages/addons/zoho/record.vue?macro=true";
import { default as indexUyXhiQheNmMeta } from "/vercel/path0/apps/webapp/pages/auth/index.vue?macro=true";
import { default as collectzTpldfUOyIMeta } from "/vercel/path0/apps/webapp/pages/collect.vue?macro=true";
import { default as _91id_93fo7uOg6DhGMeta } from "/vercel/path0/apps/webapp/pages/contact-form/[id].vue?macro=true";
import { default as playertpKmoSme3vMeta } from "/vercel/path0/apps/webapp/pages/embed/player.vue?macro=true";
import { default as analyticsFnZFvyraAzMeta } from "/vercel/path0/apps/webapp/pages/index/analytics.vue?macro=true";
import { default as integrationspfYshgFgbBMeta } from "/vercel/path0/apps/webapp/pages/index/integrations.vue?macro=true";
import { default as recordingsLMXaGCR1ASMeta } from "/vercel/path0/apps/webapp/pages/index/recordings.vue?macro=true";
import { default as redeemA7uuIed6LcMeta } from "/vercel/path0/apps/webapp/pages/index/redeem.vue?macro=true";
import { default as settings_46collectju3AEvt2qLMeta } from "/vercel/path0/apps/webapp/pages/index/settings.collect.vue?macro=true";
import { default as settings_46formjanzXiIe80Meta } from "/vercel/path0/apps/webapp/pages/index/settings.form.vue?macro=true";
import { default as settings_46paymentsL9wu6RJ6vXMeta } from "/vercel/path0/apps/webapp/pages/index/settings.payments.vue?macro=true";
import { default as settingsEroYZwEy1eMeta } from "/vercel/path0/apps/webapp/pages/index/settings.vue?macro=true";
import { default as index7zq5yNIRDNMeta } from "/vercel/path0/apps/webapp/pages/index.vue?macro=true";
import { default as join8FVIZO34e2Meta } from "/vercel/path0/apps/webapp/pages/join.vue?macro=true";
import { default as listen78UrYMqkpHMeta } from "/vercel/path0/apps/webapp/pages/listen.vue?macro=true";
import { default as loginRh0WsYEcKdMeta } from "/vercel/path0/apps/webapp/pages/login.vue?macro=true";
import { default as paymentoHEeQ4Fnl1Meta } from "/vercel/path0/apps/webapp/pages/payment.vue?macro=true";
import { default as frontRcOUXdVH9EMeta } from "/vercel/path0/apps/webapp/pages/record/front.vue?macro=true";
import { default as gmailLe3sRkGSGdMeta } from "/vercel/path0/apps/webapp/pages/record/gmail.vue?macro=true";
import { default as indexDShpM4vW2tMeta } from "/vercel/path0/apps/webapp/pages/record/index.vue?macro=true";
import { default as outlookNZAJKWgGaIMeta } from "/vercel/path0/apps/webapp/pages/record/outlook.vue?macro=true";
import { default as share0VHH6tjsCdMeta } from "/vercel/path0/apps/webapp/pages/record/share.vue?macro=true";
import { default as zendeskFy4Zq71XtMMeta } from "/vercel/path0/apps/webapp/pages/record/zendesk.vue?macro=true";
import { default as canny_45redirectrVCk8uiOS8Meta } from "/vercel/path0/apps/webapp/pages/static/canny-redirect.vue?macro=true";
import { default as payment_45successvAwSpUIhK1Meta } from "/vercel/path0/apps/webapp/pages/static/payment-success.vue?macro=true";
import { default as unsupported_45browserkFFJEgc2VUMeta } from "/vercel/path0/apps/webapp/pages/static/unsupported-browser.vue?macro=true";
import { default as upgradePSwq85TwIoMeta } from "/vercel/path0/apps/webapp/pages/upgrade.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/vercel/path0/apps/webapp/pages/[...slug].vue")
  },
  {
    name: "addons-zoho",
    path: "/addons/zoho",
    component: () => import("/vercel/path0/apps/webapp/pages/addons/zoho/index.vue")
  },
  {
    name: "addons-zoho-record",
    path: "/addons/zoho/record",
    component: () => import("/vercel/path0/apps/webapp/pages/addons/zoho/record.vue")
  },
  {
    name: "auth",
    path: "/auth",
    meta: indexUyXhiQheNmMeta || {},
    component: () => import("/vercel/path0/apps/webapp/pages/auth/index.vue")
  },
  {
    name: "collect",
    path: "/collect/:id",
    meta: collectzTpldfUOyIMeta || {},
    component: () => import("/vercel/path0/apps/webapp/pages/collect.vue")
  },
  {
    name: "contact-form-id",
    path: "/contact-form/:id()",
    meta: _91id_93fo7uOg6DhGMeta || {},
    component: () => import("/vercel/path0/apps/webapp/pages/contact-form/[id].vue")
  },
  {
    name: "embed-player",
    path: "/embed/player/:id",
    meta: playertpKmoSme3vMeta || {},
    component: () => import("/vercel/path0/apps/webapp/pages/embed/player.vue")
  },
  {
    name: "index",
    path: "/",
    meta: index7zq5yNIRDNMeta || {},
    component: () => import("/vercel/path0/apps/webapp/pages/index.vue"),
    children: [
  {
    name: "index-analytics",
    path: "/analytics/:id",
    meta: analyticsFnZFvyraAzMeta || {},
    component: () => import("/vercel/path0/apps/webapp/pages/index/analytics.vue")
  },
  {
    name: "index-integrations",
    path: "integrations",
    component: () => import("/vercel/path0/apps/webapp/pages/index/integrations.vue")
  },
  {
    name: "index-recordings",
    path: "recordings",
    meta: recordingsLMXaGCR1ASMeta || {},
    alias: ["/"],
    component: () => import("/vercel/path0/apps/webapp/pages/index/recordings.vue")
  },
  {
    name: "index-redeem",
    path: "redeem",
    meta: redeemA7uuIed6LcMeta || {},
    alias: ["/redeem/:code?"],
    component: () => import("/vercel/path0/apps/webapp/pages/index/redeem.vue")
  },
  {
    name: "index-settings.collect",
    path: "/settings/collect",
    meta: settings_46collectju3AEvt2qLMeta || {},
    component: () => import("/vercel/path0/apps/webapp/pages/index/settings.collect.vue")
  },
  {
    name: "index-settings.form",
    path: "/settings/form",
    meta: settings_46formjanzXiIe80Meta || {},
    component: () => import("/vercel/path0/apps/webapp/pages/index/settings.form.vue")
  },
  {
    name: "index-settings.payments",
    path: "/settings/payments",
    meta: settings_46paymentsL9wu6RJ6vXMeta || {},
    component: () => import("/vercel/path0/apps/webapp/pages/index/settings.payments.vue")
  },
  {
    name: "index-settings",
    path: "settings",
    meta: settingsEroYZwEy1eMeta || {},
    component: () => import("/vercel/path0/apps/webapp/pages/index/settings.vue")
  }
]
  },
  {
    name: "join",
    path: "/join",
    meta: join8FVIZO34e2Meta || {},
    alias: ["/join/:code?"],
    component: () => import("/vercel/path0/apps/webapp/pages/join.vue")
  },
  {
    name: "listen",
    path: "/listen/:id",
    meta: listen78UrYMqkpHMeta || {},
    component: () => import("/vercel/path0/apps/webapp/pages/listen.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginRh0WsYEcKdMeta || {},
    component: () => import("/vercel/path0/apps/webapp/pages/login.vue")
  },
  {
    name: "payment",
    path: "/payment",
    component: () => import("/vercel/path0/apps/webapp/pages/payment.vue")
  },
  {
    name: "record-front",
    path: "/record/front",
    meta: frontRcOUXdVH9EMeta || {},
    alias: ["/integration/front"],
    component: () => import("/vercel/path0/apps/webapp/pages/record/front.vue")
  },
  {
    name: "record-gmail",
    path: "/record/gmail/:id",
    meta: gmailLe3sRkGSGdMeta || {},
    alias: ["/gmail-record/:id"],
    component: () => import("/vercel/path0/apps/webapp/pages/record/gmail.vue")
  },
  {
    name: "record",
    path: "/record",
    meta: indexDShpM4vW2tMeta || {},
    component: () => import("/vercel/path0/apps/webapp/pages/record/index.vue")
  },
  {
    name: "record-outlook",
    path: "/record/outlook",
    meta: outlookNZAJKWgGaIMeta || {},
    alias: ["/outlook"],
    component: () => import("/vercel/path0/apps/webapp/pages/record/outlook.vue")
  },
  {
    name: "record-share",
    path: "/record/share/:id",
    meta: share0VHH6tjsCdMeta || {},
    component: () => import("/vercel/path0/apps/webapp/pages/record/share.vue")
  },
  {
    name: "record-zendesk",
    path: "/record/zendesk",
    component: () => import("/vercel/path0/apps/webapp/pages/record/zendesk.vue")
  },
  {
    name: "static-canny-redirect",
    path: "/static/canny-redirect",
    meta: canny_45redirectrVCk8uiOS8Meta || {},
    component: () => import("/vercel/path0/apps/webapp/pages/static/canny-redirect.vue")
  },
  {
    name: "static-payment-success",
    path: "/static/payment-success",
    meta: payment_45successvAwSpUIhK1Meta || {},
    component: () => import("/vercel/path0/apps/webapp/pages/static/payment-success.vue")
  },
  {
    name: "static-unsupported-browser",
    path: "/static/unsupported-browser",
    meta: unsupported_45browserkFFJEgc2VUMeta || {},
    component: () => import("/vercel/path0/apps/webapp/pages/static/unsupported-browser.vue")
  },
  {
    name: "upgrade",
    path: "/upgrade",
    component: () => import("/vercel/path0/apps/webapp/pages/upgrade.vue")
  }
]